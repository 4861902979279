<div
  class="overlay"
  style="
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    scale: 5;
    justify-content: center;
    flex-direction: column;
  "
>
  <div #svgContainerr class="svg-containerr" style="display: flex">
    <svg
      id="svg1"
      class="svg-itemm"
      width="80"
      height="171"
      viewBox="0 0 80 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.8472 77.2398L54.4372 66.7598L50.9972 71.1898L25.1572 104.24L50.5972 124.12C53.7877 126.615 55.857 130.275 56.3501 134.295C56.8433 138.316 55.72 142.367 53.2272 145.56L62.6272 133.56L73.1072 120.15C75.583 116.988 77.4111 113.37 78.4868 109.501C79.5625 105.632 79.8647 101.59 79.3761 97.6039C78.8876 93.6183 77.6178 89.7681 75.6395 86.2738C73.6613 82.7795 71.0133 79.7095 67.8472 77.2398Z"
        fill="url(#paint0_linear_946_4373)"
      />
      <path
        d="M14.6776 117.65L25.1476 104.24L50.5976 124.12C53.7881 126.616 55.8573 130.276 56.3505 134.296C56.8436 138.316 55.7204 142.368 53.2276 145.56L33.3476 171L19.9376 160.52C16.7772 158.051 14.1342 154.983 12.1595 151.492C10.1847 148.001 8.91693 144.156 8.42849 140.175C7.94005 136.194 8.24052 132.156 9.31274 128.291C10.385 124.426 12.2079 120.81 14.6776 117.65Z"
        fill="url(#paint1_linear_946_4373)"
      />
      <path
        d="M11.7373 93.7704L25.1473 104.24L28.6073 99.8104L54.4273 66.8104L28.9973 46.8804C27.4164 45.6457 26.0943 44.1115 25.1065 42.3656C24.1187 40.6198 23.4845 38.6964 23.2403 36.7053C22.9961 34.7143 23.1465 32.6946 23.6831 30.7618C24.2196 28.8289 25.1317 27.0207 26.3673 25.4404L16.9973 37.4804L6.47732 50.8904C1.48912 57.2744 -0.75902 65.3783 0.227401 73.4197C1.21382 81.4611 5.35401 88.7814 11.7373 93.7704Z"
        fill="url(#paint2_linear_946_4373)"
      />
      <path
        d="M64.9071 53.35L54.4371 66.76L28.9971 46.88C27.4162 45.6452 26.0941 44.1111 25.1063 42.3652C24.1184 40.6193 23.4843 38.6959 23.2401 36.7049C22.9958 34.7139 23.1463 32.6942 23.6828 30.7613C24.2194 28.8285 25.1315 27.0203 26.3671 25.44L46.2371 0L59.6471 10.48C62.8075 12.9494 65.4505 16.0172 67.4252 19.5081C69.3999 22.999 70.6677 26.8447 71.1562 30.8256C71.6446 34.8065 71.3441 38.8446 70.2719 42.7093C69.1997 46.5741 67.3767 50.1898 64.9071 53.35Z"
        fill="url(#paint3_linear_946_4373)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_946_4373"
          x1="-3.58277"
          y1="106.16"
          x2="79.6272"
          y2="106.16"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.32" stop-color="#63B030" />
          <stop offset="0.73" stop-color="#378231" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_946_4373"
          x1="19.2036"
          y1="138.851"
          x2="60.5034"
          y2="133.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.19" stop-color="#F5C100" />
          <stop offset="0.53" stop-color="#F2A305" />
          <stop offset="1" stop-color="#ED730C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_946_4373"
          x1="59.8273"
          y1="64.8404"
          x2="-23.3827"
          y2="64.8404"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stop-color="#00399D" />
          <stop offset="0.4" stop-color="#0040A1" />
          <stop offset="0.54" stop-color="#0155AD" />
          <stop offset="0.72" stop-color="#0276C0" />
          <stop offset="0.83" stop-color="#0390CF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_946_4373"
          x1="60.3845"
          y1="32.1425"
          x2="19.0748"
          y2="37.2147"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.27" stop-color="#D51019" />
          <stop offset="0.86" stop-color="#A71917" />
        </linearGradient>
      </defs>
    </svg>

    <svg
      id="svg2"
      class="svg-itemm"
      width="80"
      height="171"
      viewBox="0 0 80 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.8472 77.2398L54.4372 66.7598L50.9972 71.1898L25.1572 104.24L50.5972 124.12C53.7877 126.615 55.857 130.275 56.3501 134.295C56.8433 138.316 55.72 142.367 53.2272 145.56L62.6272 133.56L73.1072 120.15C75.583 116.988 77.4111 113.37 78.4868 109.501C79.5625 105.632 79.8647 101.59 79.3761 97.6039C78.8876 93.6183 77.6178 89.7681 75.6395 86.2738C73.6613 82.7795 71.0133 79.7095 67.8472 77.2398Z"
        fill="url(#paint0_linear_946_4636)"
      />
      <path
        d="M14.6776 117.65L25.1476 104.24L50.5976 124.12C53.7881 126.616 55.8573 130.276 56.3505 134.296C56.8436 138.316 55.7204 142.368 53.2276 145.56L33.3476 171L19.9376 160.52C16.7772 158.051 14.1342 154.983 12.1595 151.492C10.1847 148.001 8.91693 144.156 8.42849 140.175C7.94005 136.194 8.24052 132.156 9.31274 128.291C10.385 124.426 12.2079 120.81 14.6776 117.65Z"
        fill="url(#paint1_linear_946_4636)"
      />
      <path
        d="M11.7373 93.7704L25.1473 104.24L28.6073 99.8104L54.4273 66.8104L28.9973 46.8804C27.4164 45.6457 26.0943 44.1115 25.1065 42.3656C24.1187 40.6198 23.4845 38.6964 23.2403 36.7053C22.9961 34.7143 23.1465 32.6946 23.6831 30.7618C24.2196 28.8289 25.1317 27.0207 26.3673 25.4404L16.9973 37.4804L6.47732 50.8904C1.48912 57.2744 -0.75902 65.3783 0.227401 73.4197C1.21382 81.4611 5.35401 88.7814 11.7373 93.7704Z"
        fill="url(#paint2_linear_946_4636)"
      />
      <path
        d="M64.9071 53.35L54.4371 66.76L28.9971 46.88C27.4162 45.6452 26.0941 44.1111 25.1063 42.3652C24.1184 40.6193 23.4843 38.6959 23.2401 36.7049C22.9958 34.7139 23.1463 32.6942 23.6828 30.7613C24.2194 28.8285 25.1315 27.0203 26.3671 25.44L46.2371 0L59.6471 10.48C62.8075 12.9494 65.4505 16.0172 67.4252 19.5081C69.3999 22.999 70.6677 26.8447 71.1562 30.8256C71.6446 34.8065 71.3441 38.8446 70.2719 42.7093C69.1997 46.5741 67.3767 50.1898 64.9071 53.35Z"
        fill="url(#paint3_linear_946_4636)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_946_4636"
          x1="37.5691"
          y1="107.612"
          x2="84.2197"
          y2="102.138"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.19" stop-color="#F5C100" />
          <stop offset="0.53" stop-color="#F2A305" />
          <stop offset="1" stop-color="#ED730C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_946_4636"
          x1="45.4639"
          y1="136.383"
          x2="4.14911"
          y2="141.456"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.27" stop-color="#D51019" />
          <stop offset="0.86" stop-color="#A71917" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_946_4636"
          x1="-28.7302"
          y1="64.8404"
          x2="54.4515"
          y2="64.8404"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.32" stop-color="#63B030" />
          <stop offset="0.73" stop-color="#378231" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_946_4636"
          x1="76.1713"
          y1="33.38"
          x2="2.39463"
          y2="33.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stop-color="#00399D" />
          <stop offset="0.4" stop-color="#0040A1" />
          <stop offset="0.54" stop-color="#0155AD" />
          <stop offset="0.72" stop-color="#0276C0" />
          <stop offset="0.83" stop-color="#0390CF" />
        </linearGradient>
      </defs>
    </svg>

    <svg
      id="svg3"
      class="svg-itemm"
      width="81"
      height="171"
      viewBox="0 0 81 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.8272 77.2398L55.4172 66.7598L51.9772 71.1898L26.1372 104.24L51.5772 124.12C54.7677 126.615 56.837 130.275 57.3301 134.295C57.8233 138.316 56.7 142.367 54.2072 145.56L63.6072 133.56L74.0872 120.15C76.563 116.988 78.3911 113.37 79.4668 109.501C80.5425 105.632 80.8447 101.59 80.3561 97.6039C79.8676 93.6183 78.5978 89.7681 76.6195 86.2738C74.6413 82.7795 71.9933 79.7095 68.8272 77.2398Z"
        fill="url(#paint0_linear_946_4427)"
      />
      <path
        d="M15.6573 117.65L26.1273 104.24L51.5773 124.12C54.7678 126.616 56.837 130.276 57.3302 134.296C57.8234 138.316 56.7001 142.368 54.2073 145.56L34.3273 171L20.9173 160.52C17.7569 158.051 15.1139 154.983 13.1392 151.492C11.1645 148.001 9.89667 144.156 9.40823 140.175C8.91979 136.194 9.22026 132.156 10.2925 128.291C11.3647 124.426 13.1877 120.81 15.6573 117.65Z"
        fill="url(#paint1_linear_946_4427)"
      />
      <path
        d="M12.7173 93.7704L26.1273 104.24L29.5873 99.8104L55.4073 66.8104L29.9773 46.8804C28.3964 45.6457 27.0743 44.1115 26.0865 42.3656C25.0987 40.6198 24.4645 38.6964 24.2203 36.7053C23.976 34.7143 24.1265 32.6946 24.663 30.7618C25.1996 28.8289 26.1117 27.0207 27.3473 25.4404L17.9773 37.4804L7.4573 50.8904C2.4691 57.2744 0.22096 65.3783 1.20738 73.4197C2.1938 81.4611 6.33399 88.7814 12.7173 93.7704Z"
        fill="url(#paint2_linear_946_4427)"
      />
      <path
        d="M65.8873 53.35L55.4173 66.76L29.9773 46.88C28.3964 45.6452 27.0743 44.1111 26.0865 42.3652C25.0987 40.6193 24.4645 38.6959 24.2203 36.7049C23.9761 34.7139 24.1265 32.6942 24.6631 30.7613C25.1996 28.8285 26.1117 27.0203 27.3473 25.44L47.2173 0L60.6273 10.48C63.7877 12.9494 66.4307 16.0172 68.4054 19.5081C70.3802 22.999 71.6479 26.8447 72.1364 30.8256C72.6248 34.8065 72.3244 38.8446 71.2521 42.7093C70.1799 46.5741 68.3569 50.1898 65.8873 53.35Z"
        fill="url(#paint3_linear_946_4427)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_946_4427"
          x1="68.1735"
          y1="104.699"
          x2="21.506"
          y2="110.176"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.27" stop-color="#D51019" />
          <stop offset="0.86" stop-color="#A71917" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_946_4427"
          x1="62.2324"
          y1="137.62"
          x2="-11.5535"
          y2="137.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stop-color="#00399D" />
          <stop offset="0.4" stop-color="#0040A1" />
          <stop offset="0.54" stop-color="#0155AD" />
          <stop offset="0.72" stop-color="#0276C0" />
          <stop offset="0.83" stop-color="#0390CF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_946_4427"
          x1="13.3877"
          y1="66.2931"
          x2="60.0228"
          y2="60.8223"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.19" stop-color="#F5C100" />
          <stop offset="0.53" stop-color="#F2A305" />
          <stop offset="1" stop-color="#ED730C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_946_4427"
          x1="-1.36646"
          y1="33.38"
          x2="72.3851"
          y2="33.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.32" stop-color="#63B030" />
          <stop offset="0.73" stop-color="#378231" />
        </linearGradient>
      </defs>
    </svg>

    <svg
      id="svg4"
      class="svg-itemm"
      width="80"
      height="171"
      viewBox="0 0 80 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.8472 77.2398L54.4372 66.7598L50.9972 71.1898L25.1572 104.24L50.5972 124.12C53.7877 126.615 55.857 130.275 56.3501 134.295C56.8433 138.316 55.72 142.367 53.2272 145.56L62.6272 133.56L73.1072 120.15C75.583 116.988 77.4111 113.37 78.4868 109.501C79.5625 105.632 79.8647 101.59 79.3761 97.6039C78.8876 93.6183 77.6178 89.7681 75.6395 86.2738C73.6613 82.7795 71.0133 79.7095 67.8472 77.2398Z"
        fill="url(#paint0_linear_946_4630)"
      />
      <path
        d="M14.6773 117.65L25.1473 104.24L50.5973 124.12C53.7878 126.616 55.8571 130.276 56.3502 134.296C56.8434 138.316 55.7201 142.368 53.2273 145.56L33.3473 171L19.9373 160.52C16.777 158.051 14.1339 154.983 12.1592 151.492C10.1845 148.001 8.91669 144.156 8.42825 140.175C7.93981 136.194 8.24027 132.156 9.3125 128.291C10.3847 124.426 12.2077 120.81 14.6773 117.65Z"
        fill="url(#paint1_linear_946_4630)"
      />
      <path
        d="M11.7373 93.7704L25.1473 104.24L28.6073 99.8104L54.4273 66.8104L28.9973 46.8804C27.4164 45.6457 26.0943 44.1115 25.1065 42.3656C24.1187 40.6198 23.4845 38.6964 23.2403 36.7053C22.9961 34.7143 23.1465 32.6946 23.6831 30.7618C24.2196 28.8289 25.1317 27.0207 26.3673 25.4404L16.9973 37.4804L6.47732 50.8904C1.48912 57.2744 -0.75902 65.3783 0.227401 73.4197C1.21382 81.4611 5.35401 88.7814 11.7373 93.7704Z"
        fill="url(#paint2_linear_946_4630)"
      />
      <path
        d="M64.9073 53.35L54.4373 66.76L28.9973 46.88C27.4164 45.6452 26.0943 44.1111 25.1065 42.3652C24.1187 40.6193 23.4846 38.6959 23.2403 36.7049C22.9961 34.7139 23.1465 32.6942 23.6831 30.7613C24.2196 28.8285 25.1317 27.0203 26.3673 25.44L46.2373 0L59.6473 10.48C62.8077 12.9494 65.4507 16.0172 67.4254 19.5081C69.4002 22.999 70.668 26.8447 71.1564 30.8256C71.6448 34.8065 71.3444 38.8446 70.2722 42.7093C69.1999 46.5741 67.377 50.1898 64.9073 53.35Z"
        fill="url(#paint3_linear_946_4630)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_946_4630"
          x1="85.0049"
          y1="106.16"
          x2="1.7666"
          y2="106.16"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stop-color="#00399D" />
          <stop offset="0.4" stop-color="#0040A1" />
          <stop offset="0.54" stop-color="#0155AD" />
          <stop offset="0.72" stop-color="#0276C0" />
          <stop offset="0.83" stop-color="#0390CF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_946_4630"
          x1="-17.2754"
          y1="137.62"
          x2="56.4854"
          y2="137.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.32" stop-color="#63B030" />
          <stop offset="0.73" stop-color="#378231" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_946_4630"
          x1="42.022"
          y1="63.3797"
          x2="-4.63002"
          y2="68.8532"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.27" stop-color="#D51019" />
          <stop offset="0.86" stop-color="#A71917" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_946_4630"
          x1="34.1278"
          y1="34.6107"
          x2="75.4226"
          y2="29.541"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.19" stop-color="#F5C100" />
          <stop offset="0.53" stop-color="#F2A305" />
          <stop offset="1" stop-color="#ED730C" />
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div>
    <progress id="progress-bar" max="100" value="{{progress}}">0%</progress>
  </div>
</div>
