export const environment = {
    production: false,
    name: 'dev',
     url: 'https://as-soulcore-prod-pruebas.azurewebsites.net/GeneralApi/',
     //url: 'http://localhost:5002/',
    
    portfolio:{
      url: 'https://as-soulcore-prod-pruebas.azurewebsites.net/PortfolioApi/',
      //url: 'http://localhost:5003/',
      status: 'status',
      operation:{
        solution: 'solution',
        business: 'business',
        searchController: 'search',
      }
    },
    report:{
      url: 'https://as-soulcore-prod-pruebas.azurewebsites.net/ReportApi/',
      //url: 'http://localhost:5004/',
      billingElectronicController: 'billingElectronic',
      documetOperationController: 'documetOperation',
      documentDownloandController: 'documentDownloand',
      photoOperationController: 'photoOperation',
      customsClearanceReportController: 'customsClearanceReport',
      binnacleController: 'binnacle',
      soulBotController: 'soulBot',
      dataByChartController: 'DataByChart',
    },
  
    authenticationController: 'authentication',
    changePasswordController: 'changePassword',
    oneTimePasswordController: 'oneTimePassword',
    recoverPasswordController: 'RecoveryPassword',
    userConfigController: 'userConfig',
    connectionController: 'connection',
    activityController: 'activity',
    statusActivityController: 'statusActivity',
    contactClientController: 'contactClient'
  };
  