import Swal from 'sweetalert2';

export interface ConfirmationResult {
  confirmed: boolean;
  selectedValue: string;
}

export class Toast {
  static success(message: string) {
    Swal.fire({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 8000,
      timerProgressBar: true,
      title: message,
      icon: 'success'
    });
  }

  static warning(message: string) {
    Swal.fire({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 8000,
      timerProgressBar: true,
      title: message,
      icon: 'warning'
    });
  }

  static confirmAction(message: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      Swal.fire({
        title: message,
        text: "No podrá revertir esta acción",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6571ff'
      }).then((result) => {
        resolve(!result.dismiss);
      });
    });
  }

  static error(message: string) {
    Swal.fire({
      toast: true,
      position: 'center',
      title: 'Ups, algo salió mal',
      text: message,
      icon: 'error',
      allowOutsideClick: false,
      backdrop: true,
      didOpen: () => {
        const popup = Swal.getPopup();
        if (popup) {
          popup.style.pointerEvents = 'auto';         }
      }
    });
  }

}