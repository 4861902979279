<router-outlet></router-outlet>

<div
  *ngIf="false"
  style="
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  "
>
  <img style="height: 45%" src="../../../../../assets/img/error/error.svg" />
  <h2 class="k-mt-3 k-text-center c-gray-80">
    Lo sentimos, la plataforma aún no está disponible en dispositivos móviles,
    por el momento, puedes utilizarla desde un computador. ¡Gracias por tu
    comprensión y paciencia!
  </h2>
</div>
 