import { EventEmitter, Injectable, Output } from '@angular/core';
import { finalize, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AutenticationResponse } from '../model/auth-response.model';
import { LoadingInterceptor } from '../helper/loading';
import { environment } from "environments/environment";
import { AuthenticationCredentials } from '../model/auth-credentials.model';
import { OtpUser } from '../model/otp-user.model';
import { RecoveryPassword } from '../model/recovery-password.model';
import { ChangePassword } from '../model/change-password.model';
const Cookies = require('js-cookie');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  @Output() languageChange = new EventEmitter<boolean>();
  public auth: AutenticationResponse;

  _url: string;
  _oneTimePassword: string;
  _recoverPassword: string;
  _changePassword: string;

  constructor(
    private http: HttpClient,
    private loadingInterceptor: LoadingInterceptor
  ) {
    this._url = environment.url + environment.authenticationController;
    this._oneTimePassword =
      environment.url + environment.oneTimePasswordController;
    this._recoverPassword =
      environment.url + environment.recoverPasswordController;
    this._changePassword =
      environment.url + environment.changePasswordController;
  }

  login(email: string, password: string) {
    this.loadingInterceptor.show();
    let authCredentials = new AuthenticationCredentials(email, password);

    return this.http
      .post<AutenticationResponse>(this._url, authCredentials)
      .pipe(
        map((authenticationResponse) => {
          this.auth = authenticationResponse;
          const cookieValue = JSON.stringify(authenticationResponse.token);
          this.auth.token = null as any;
          localStorage.setItem('currentUser', JSON.stringify(this.auth));
          Cookies.set('currentUser', cookieValue, { expires: 1 });
          this.getIpAddress().subscribe((res: any) => {
            localStorage.setItem('ipClient', JSON.stringify(res));
          });
          
          return authenticationResponse;
        }),
        finalize(() => {
          this.loadingInterceptor.hide();
        })
      );
  }

  public jwtUser(): any {
    const cookieValue = Cookies.get('currentUser');
    return cookieValue ? JSON.parse(cookieValue) : null;
  }

  public currentUser(): AutenticationResponse | null {
    const storedUser = localStorage.getItem('currentUser');
    if (storedUser !== null) {
      return JSON.parse(storedUser);
    } else {
      return null;
    }
  }

  public updateMenu() {
    this.languageChange.emit(true);
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.loadingInterceptor.show();
    Cookies.remove('currentUser');
    this.auth = null as any;
    this.loadingInterceptor.hide();
  }

  requetOtpForRecoveryPassword(userName: string) {
    const email = {
      userName: userName,
    };
    this.loadingInterceptor.show();
    return this.http.post(this._oneTimePassword, email).pipe(
      finalize(() => {
        this.loadingInterceptor.hide();
      })
    );
  }

  verifyOtpForRecoveryPassword(otpUser: OtpUser) {
    this.loadingInterceptor.show();
    return this.http.post(this._oneTimePassword + '/verify', otpUser).pipe(
      finalize(() => {
        this.loadingInterceptor.hide();
      })
    );
  }

  recoveryPassword(recoveryPassword: RecoveryPassword) {
    this.loadingInterceptor.show();
    return this.http.patch(this._recoverPassword, recoveryPassword).pipe(
      finalize(() => {
        this.loadingInterceptor.hide();
      })
    );
  }

  changePassword(changePassword: ChangePassword) {
    this.loadingInterceptor.show();
    return this.http.patch(this._changePassword, changePassword).pipe(
      finalize(() => {
        this.loadingInterceptor.hide();
      })
    );
  }

  public getIpAddress() {
    const headers = new HttpHeaders().set('Skip-Interceptor', 'true');
    return this.http.get('https://ipinfo.io/json', { headers });
  }
}
