import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-loading-downloand',
  standalone: true,
  imports: [],
  templateUrl: './loading-downloand.component.html',
  styleUrl: './loading-downloand.component.scss',
})
export class LoadingDownloandComponent implements AfterViewInit {
  @ViewChild('svgContainerr') svgContainer: ElementRef;
  private svgs: NodeListOf<SVGElement>;
  private currentIndex: number = 0;
  intervalId: NodeJS.Timeout;

  ngAfterViewInit() {
    this.svgs = this.svgContainer.nativeElement.querySelectorAll('.svg-itemm');
    this.showNextSvg();
  }

  private showNextSvg() {
    this.svgs.forEach((svg, index) => {
      svg.classList.remove('active');
      if (index === this.currentIndex) {
        svg.classList.add('active');
      }
    });

    this.currentIndex = (this.currentIndex + 1) % this.svgs.length;

    this.intervalId = setTimeout(() => this.showNextSvg(), 250);
  }

  get progress(): number {
    const progress = sessionStorage.getItem('progressDowlond');
    return progress ? parseInt(progress) : 0;
  }
}
