import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  browserLanguage: string;
  showSpinner = false;
  title = 'Soul Clientes';

  isAuthorized$!: Observable<boolean>;

  ngOnInit(): void {
    this.onResize();
  }

  constructor(
    private router: Router,
    ){}


  onToggleSpinner() : void {
    this.showSpinner = !this.showSpinner;
  }



  onSignOut() : void {
    localStorage.removeItem('token');
    this.router.navigate(['/auth/login']);
  }

  isDesktop: boolean = true;

  screenWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if(this.screenWidth <= 1100)
    {
      this.isDesktop = false;
    }
    else{
      this.isDesktop = true;
    }
  }
}
