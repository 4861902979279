import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const jwtUser = this.authenticationService.jwtUser();
    const ipUser = localStorage.getItem('ipClient');

    if (jwtUser && !request.headers.has('Skip-Interceptor')) {
      if (ipUser) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${jwtUser}`,
            'X-Client-Browser': this.getClientBrowser(),
            'X-Client-IP': JSON.parse(ipUser).ip,
            'X-Client-Ubication': encodeURIComponent(
              JSON.parse(ipUser).city +
                ' / ' +
                JSON.parse(ipUser).region +
                ' / ' +
                JSON.parse(ipUser).country +
                ' / ' +
                JSON.parse(ipUser).postal
            ),
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${jwtUser}`,
            'X-Client-Browser': this.getClientBrowser(),
            'X-Client-IP': 'Undefined',
            'X-Client-Ubication': 'Undefined',
          },
        });
      }
    }

    return next.handle(request);
  }

  private getClientBrowser(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('chrome') > -1 && agent.indexOf('edg') === -1:
        return 'Chrome';
      case agent.indexOf('firefox') > -1:
        return 'Firefox';
      case agent.indexOf('safari') > -1 && agent.indexOf('chrome') === -1:
        return 'Safari';
      case agent.indexOf('edg/') > -1:
        return 'Edge';
      case agent.indexOf('opr') > -1 || agent.indexOf('opera') > -1:
        return 'Opera';
      case agent.indexOf('msie') > -1 || agent.indexOf('trident/') > -1:
        return 'Internet Explorer';
      default:
        return 'Unknown';
    }
  }
}
