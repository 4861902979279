import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LoadingComponent } from '../widget/loading/loading.component';
import { LoadingDownloandComponent } from '../widget/loading-downloand/loading-downloand.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingInterceptor {

  private loadingModal: MatDialogRef<any>;

  constructor(private dialog: MatDialog) { }

  show() {
    if (!this.loadingModal) {
      const dialogConfig: MatDialogConfig = {
        disableClose: true,
        width: '30%',
      };

      this.loadingModal = this.dialog.open(LoadingComponent, dialogConfig);
    }
  }

  hide() {
    if (this.loadingModal) {
      this.loadingModal.close();
      this.loadingModal = null as any;
    }
  }


  dowloand() {
    if (!this.loadingModal) {
      const dialogConfig: MatDialogConfig = {
        disableClose: true,
        width: '30%',
      };

      this.loadingModal = this.dialog.open(LoadingDownloandComponent, dialogConfig);
    }
  }
}
